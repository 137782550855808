import React, {useState} from "react"
import Turn from "../components/turn"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

export default function TurnLayout({data}){
  let [status,setStatus] = useState({})
  data.markdownRemark.frontmatter.type="turn"
  return <Layout data={data} status={status}>
  <Turn data={data} setStatus={setStatus}>
    {data.markdownRemark.frontmatter.images
      .map(image =>image.childImageSharp.gatsbyImageData)
      .map((imData,i) =>
      <div key={i} className="spread">
          <GatsbyImage loading={"eager"} image={getImage(imData)} alt=""/>
      </div>
      )
    }
  </Turn>
  </Layout>
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        tags
        display
        vangogh_object
        mobile_view
        audio {
          publicURL
        }
        background_images {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        background_videos {
          publicURL
        }
        background_color
        background_style
        images {
          publicURL
          childImageSharp {
            gatsbyImageData(
               width: 2000
               placeholder: DOMINANT_COLOR
             )
          }
        }
      }
      fields {
        slug
        table
      }
    }
  }
`
